<template>
    <ion-page>
        <TheHeader />

        <ion-content :fullscreen="true">
            <div v-if="isLoaded" class="container">
                <template v-if="hasData">
                    <h1 class="fs-20 text-center">
                        {{ $t("general.lookingFor") }}
                    </h1>
                    <ion-card
                        v-for="(offer, index) in offerList"
                        :key="index"
                        class="card-custom"
                        @click="goToOffer(offer)"
                    >
                        <ion-card-header>
                            <ion-card-title class="d-flex align-items-start">
                                <img
                                    :src="offer.productIconUrl"
                                    alt="x"
                                    class="mr-3"
                                />
                                <div>
                                    <h1 class="m-0 fs-20">
                                        {{ offer.translatedName }}
                                    </h1>
                                    <div class="badge-list">
                                        <span
                                            class="
                                                badge
                                                text-lowercase text-white
                                                default-orange
                                            "
                                            :style="
                                                'background-color:' +
                                                offer.manufactureColor +
                                                ';'
                                            "
                                            >{{ offer.manufacture }}</span
                                        >
                                        <span
                                            class="
                                                badge badge-outline
                                                text-lowercase
                                            "
                                        >
                                            {{ offer.variety }}
                                        </span>
                                    </div>
                                </div>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <hr />
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span class="fs-12">{{
                                    $t("general.production")
                                }}</span>
                                <strong>{{
                                    getColtureName(offer.farmingType)
                                }}</strong>
                            </div>
                            <hr />
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span class="fs-12">{{
                                    $t("general.transport")
                                }}</span>
                                <strong>{{ offer.transport }}</strong>
                            </div>
                            <hr />
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span class="fs-12">{{
                                    $t("general.quantity")
                                }}</span>
                                <strong>{{ offer.quantity }}Kg</strong>
                            </div>
                            <hr />

                            <button class="btn btn-success w-100 mt-3">
                                {{ $t("general.viewPrices") }}
                            </button>
                        </ion-card-content>
                    </ion-card>
                </template>
                <h1
                    v-else
                    class="fs-20 text-center"
                    v-html="$t('general.noOffers')"
                ></h1>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Dashboard.ts"></script>

import { Vue, Options } from 'vue-class-component';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    // modalController,
} from '@ionic/vue';

// import Panel from '../../Panel/Panel.vue';
import { SET_APP_LOADING } from '@/core/store/action-types';
import fruttaMeClient from '@/core/api/fruttame-service';
import { getColtureName } from '@/core/utilities/utils';
import { mapState } from 'vuex';
import {
    IResult,
    PlantReferenceDTO,
    SupplierOfferListDTO,
    UsermeDTO,
} from '@/../client-generator/generated-clients/client';
import { PAGE_CALENDAR } from '@/core/router/pages';

@Options({
    name: 'Dashboard',
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        // Panel,
    },
    computed: {
        ...mapState(['profile']),
    },
})
export default class Dashboard extends Vue {
    profile!: UsermeDTO;
    panelDemo: HTMLIonModalElement | null = null;
    offerList: SupplierOfferListDTO[] = [];
    isLoaded = false;
    getColtureName = getColtureName;

    get hasData() {
        return this.offerList && this.offerList.length > 0;
    }

    mounted() {
        this.fetchData();
    }

    async fetchData() {
        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            const response = await fruttaMeClient.offers(
                '',
                (this.profile.plant as PlantReferenceDTO).plantId as number
            );
            this.offerList = response.data as SupplierOfferListDTO[];
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult).errorMessage ?? this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
            this.isLoaded = true;
        }
    }

    goToOffer(offer: SupplierOfferListDTO) {
        this.$router.push({
            name: PAGE_CALENDAR,
            params: {
                offer: JSON.stringify(offer),
            },
        });
    }

    // async openPanel() {
    //     this.panelDemo = await modalController.create({
    //         component: Panel,
    //         cssClass: 'panel',
    //         componentProps: {
    //             closePanel: this.closePanel,
    //         },
    //     });

    //     this.panelDemo.present();
    // }

    // closePanel() {
    //     if (this.panelDemo) {
    //         this.panelDemo.dismiss();
    //     }
    // }
}
